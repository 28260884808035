import { fetchWrapper } from "~root/_helpers";

const baseUrl = process.env.REACT_APP_SERVER_URL + "/database/plans";

export const planService = {
  getAllPlans,
  getOnboxPlans,
  getSpecificPlan,
  getMyPlan,
  getDaySurveyResult,

  saveWholePlan,
  createFromAssistent,
  updatePlan,
  toggleSharingPlan,

  assignUser,

  deletePlan,
  getGeneratedSurveyDay,
};

//#region GET REQUESTS

function getAllPlans() {
  return fetchWrapper.get(`${baseUrl}/all-plans`);
}

function getOnboxPlans() {
  return fetchWrapper.get(`${baseUrl}/onbox-plans`);
}

function getSpecificPlan(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function getMyPlan() {
  return fetchWrapper.get(`${baseUrl}`);
}


function getGeneratedSurveyDay(id){
  return fetchWrapper.get(`${baseUrl}/generated-survey-day/${id}`);
}

//#endregion GET REQUESTS

//#region POST REQUESTS

function saveWholePlan(name, days, finished) {
  return fetchWrapper.post(`${baseUrl}/add-whole-plan`, {
    name,
    days,
    finished,
    getDaySurveyResult
  });
}

function createFromAssistent(props){
  return fetchWrapper.post(`${baseUrl}/assistant`, props);
}
//#endregion POST REQUESTS

//#region PUT REQUESTS

function updatePlan(id, name, days, finished) {
  return fetchWrapper.put(`${baseUrl}/update/${id}`, { name, days, finished });
}

function assignUser(params) {
  return fetchWrapper.put(`${baseUrl}/assign-user`, params);
}

function toggleSharingPlan(id) {
  return fetchWrapper.put(`${baseUrl}/toggle-sharing/${id}`);
}

function getDaySurveyResult(day_id){
  return fetchWrapper.get(`${baseUrl}/day-survey-result/${day_id}`);
}

//#endregion PUT REQUESTS

//#region delete

function deletePlan(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}



