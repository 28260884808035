import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
import { accountService } from './_services'
import HttpsRedirect from 'react-https-redirect';

accountService.refreshToken().finally(startApp);


function startApp() {
  render(
    <HttpsRedirect>
      <>
        <App />
      </>
    </HttpsRedirect>,
    document.getElementById('root')
  )
}
