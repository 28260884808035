import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/database/responsibilities";

export const responsibilitiesService = {
    saveResponsibilities,
    getResponsibilities,
    getOnboxResponsibilities
};

/* #region  POST */

function saveResponsibilities(items) {
    return fetchWrapper.post(baseUrl, {items});
}

function getResponsibilities() {
    return fetchWrapper.get(baseUrl);
}

function getOnboxResponsibilities() {
    return fetchWrapper.get(baseUrl + "/responsibilitiesOnbox");
}