import React, { useState } from 'react';
// import { Document, Page,  } from 'react-pdf';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {alertService} from '~root/_services'



const Pages = ({numberOfPages}) => {

    var renderedPages = [];
    
    for(var i = 1; i <= numberOfPages; i++){
        renderedPages.push(<Page scale={1} pageNumber={i} renderTextLayer={false} key={`Page-${i}`}/>);
    }


    return renderedPages

}


export default ({fileUrl, onLoadSuccess,onLoadError, appendServerUrl = false, innerRef=null}) => {
    const [pagesCount, setPagesCount] = useState(0);

    const handleSuccessLoad = ({numPages}) => {
        setPagesCount(numPages);

        if(onLoadSuccess !== void 0){
            onLoadSuccess(numPages);
        }
    }

    const handleErrorLoad = () => {
        console.log("err")
        if(handleErrorLoad !== void 0){
            onLoadError();
        }
        alertService.error("Błąd w załadowaniu pliku PDF.")
    }

        const file = appendServerUrl  ? process.env.REACT_APP_SERVER_URL + '/' + fileUrl : fileUrl
    return (
        <div>
            <Document renderMode="canvas" ref={innerRef} onSourceError={handleErrorLoad} file={file} onLoadError={handleErrorLoad} onLoadSuccess={handleSuccessLoad}>
                <Pages numberOfPages={pagesCount}/>
            </Document>
        </div>
    )

}