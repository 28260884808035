import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TopbarProfile from "./TopbarProfile";
import TopbarNotifications from "./TopbarNotifications";
import SharedIcon, {ICON_COLOR} from "~shared/components/SharedIcon";

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
        <div style={{marginRight:"auto", marginLeft:8}} className="cursor-pointer" onClick={this.props.changeSidebarVisibility}>
        <SharedIcon icon="toggler" colorType={ICON_COLOR.ORANGE_FILLED}/>

        </div>
          {/* <TopbarSearch /> */}
          <TopbarNotifications />
          <TopbarProfile />
        </div>
      </div>
    );
  }
}

export default Topbar;
