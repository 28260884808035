
import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/notifications";


export const notificationService = {
    getNotifications,
    createNotification,
    setNotificationAsReaded,
};



//#region                              =============  POST ==============

function createNotification(title, toUser){
    return fetchWrapper.post(baseUrl, {title, toUser});
}

//#endregion

//#region                              =============  GET ==============
 

function getNotifications() {
    return fetchWrapper.get(`${baseUrl}`);

}

//#endregion

//#region                              =============  PUT ==============
 

function setNotificationAsReaded(id) {
    return fetchWrapper.put(`${baseUrl}/${id}`);

}

//#endregion