import {
    CHANGE_COLOR_TO_ORANGE,
    CHANGE_COLOR_TO_BLUE,
    CHANGE_COLOR_TO_GREEN,
    CHANGE_COLOR_TO_RED,
} from '../actions/colorActions';

const initialState = {
    color: 'orange',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_COLOR_TO_ORANGE:
            return { color: 'orange' };
        case CHANGE_COLOR_TO_BLUE:
            return { color: 'blue' };
        case CHANGE_COLOR_TO_GREEN:
            return { color: 'green' };
        case CHANGE_COLOR_TO_RED:
            return { color: 'red' };
        default:
            return state;
    }
}
