import React, { useEffect, useState, useRef, createRef } from "react";
import PDFViewer from "~shared/components/DocumentViewer/PDFViewer";
import SideCollapse from "./SideCollapse";

export default function Instruction({ src }) {
  const pdf_ref = useRef();
  const [url, setUrl] = useState("");
  const [loaded, toggleLoaded] = useState(false);

  const [parentUnmounted, toggleUnmout] = useState(true)

  const parentRef = createRef();

  useEffect(() => {
    parentRef.current.toggleUnmount(false)
    setUrl(process.env.REACT_APP_SERVER_URL + "/file/get/" + src._id);
  }, [parentRef,src]);



  return (
    <SideCollapse iconClass="icon-instructions" icon="help" contentClass="pdf-container" ref={parentRef} unmountedChanged={toggleUnmout}>
      {!loaded && (
        <div>
          <h3>Trwa ładowanie...</h3>
        </div>
      )}
      {!parentUnmounted &&
        <PDFViewer
          fileUrl={url}
          onLoadError={() => {
            console.log("Error while loading pdf file");
          }}
          onLoadSuccess={() => {
            toggleLoaded(true);
          }}
          innerRef={pdf_ref}
        />
      }
    </SideCollapse>

  )
}
