import { faEdit, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import {
  ArrowBack, Assignment, Attachment,
  BarChart, ChatOutlined, Check, ChevronRight, CloudUpload, DateRangeOutlined, DeleteOutline, Devices, DragIndicator, ExploreOutlined, FlightLand, GroupRounded, HelpOutlined, KeyboardArrowDown,
  KeyboardArrowUp, ListAlt, Menu, MenuBook, PhoneOutlined, Queue, RadioButtonChecked, RoomOutlined, Send,
  ViewStream, Visibility, WatchLater, WidgetsRounded, PlaylistAddCheck, Assistant, OndemandVideo, Close
} from "@material-ui/icons";
import FolderSharedOutlinedIcon from '@material-ui/icons/FolderSharedOutlined';
import ChevronDoubleLeftIcon from "mdi-react/ChevronDoubleLeftIcon";
import ChevronDoubleRightIcon from "mdi-react/ChevronDoubleRightIcon";
import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import DatabaseIcon from "mdi-react/DatabaseIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import SchoolIcon from "mdi-react/SchoolIcon";
import AddIcon from "mdi-react/ShapeSquarePlusIcon";
import ResponsibilitiesIcon from "mdi-react/ClipboardListOutlineIcon";
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import React from "react";
import onbox_icon from '~img/button-color.png';
import { ReactComponent as PDFIcon } from "~shared/icons/pdf-flat.svg";
import { ReactComponent as TimeIcon } from "~shared/icons/time.svg";
import { ReactComponent as XLSIcon } from "~shared/icons/xls-flat.svg";





const getIconSizeClass = (size) => {
  switch (size) {
    case ICON_SIZE.XS:
      return "icon__size_extrasmall";
    case ICON_SIZE.SM:
      return "icon__size_small";
    case ICON_SIZE.MD:
      return "icon__size_medium";
    case ICON_SIZE.LG:
      return "icon__size_large";
    case ICON_SIZE.SIZE24:
      return "icon__size_24";
    default:
      return "icon__size_default";
  }
};

export const ICON_SIZE = {
  DEFAULT: "default",
  MD: "md",
  LG: "lg",
  SM: "sm",
  XS: "xs",
  SIZE24:"size24"
};

export const ICON_COLOR = {
  ORANGE: "orange-icon",
  ORANGE_LIGHT: "orange-icon__light",
  GRAY: "gray-icon",
  DARK_GRAY: "dark-gray-icon",
  BLACK: "black-icon",
  ORANGE_FILLED:"orange-icon__filled",
  SOLID_ORANGE:"solid-orange-icon"
};

export const ICON_PADDING = {
  MEDIUM: "icon__padding_medium",
};

export const ICON_BACKGROUND_COLOR = {};

const OnboxIcon = ()=>{
  return <img className="MuiSvgIcon-root" alt="" src={onbox_icon}/>
}

function SharedIcon({
  icon,
  muted = false,
  active = false,
  size = ICON_SIZE.DEFAULT,
  colorType = ICON_COLOR.ORANGE,
  padding = "",
  progress = false,
  value, // Value for progress
  className=""
}) {
  const icons = {
    stack: <WidgetsRounded />,
    onboarding: <Assignment />,
    onboarder: <ExploreOutlined />,
    team: <GroupRounded />,
    phone: <PhoneOutlined />,
    pin: <RoomOutlined />,
    calendar: <DateRangeOutlined />,
    star: <FontAwesomeIcon icon={faTrophy} size="xs"/>,
    pdf: <PDFIcon />,
    xls: <XLSIcon />,
    "edit-task": <FontAwesomeIcon icon={faEdit} />,
    time: <TimeIcon />,
    "chevron-right": <ChevronRight />,
    "chevron-up": <KeyboardArrowUp />,
    "chevron-down": <KeyboardArrowDown />,
    "double-chevron-right": <ChevronDoubleRightIcon />,
    "double-chevron-left": <ChevronDoubleLeftIcon />,
    student: <SchoolIcon />,
    open: <OpenIcon />,
    add: <AddIcon />,
    database: <DatabaseIcon />,
    message: <ChatOutlined />,
    "clock-filled":<WatchLater/>,
    delete: <TrashCanOutlineIcon />,
    radio: <RadioButtonChecked />,
    accept:<Check/>,
    back:<ArrowBack/>,
    trash:<DeleteOutline/>,
    new_user:<Queue/>,
    drag: <DragIndicator />,
    attachement: <Attachment />,
    eye:<Visibility/>,
    toggler:<Menu/>,
    onbox_btn: <OnboxIcon/>,
    chart: <BarChart />,
    help:<HelpOutlined/>,
    upload:<CloudUpload/>,
    copy:<ContentCopyIcon/>,
    send: <Send />,
    section: <ViewStream />,
    training: <MenuBook />,
    tasks: <ListAlt/>,
    meetings : <Devices/>,
    preboarding: <FlightLand/>,
    checklist:<PlaylistAddCheck/>,
    assistant:<Assistant/>,
    responsibilities:<ResponsibilitiesIcon/>,
    videoIcon: <OndemandVideo/>,
    close : <Close/>,
    folderMeetings: <FolderSharedOutlinedIcon />,
  };

  const iconSizeClass = getIconSizeClass(size);

  return (
    <div
      className={`shared__icon ${colorType} ${muted ? "muted" : ""} ${
        active ? "active" : ""
      } ${iconSizeClass} ${padding} ${className}`}
    >
      {icons[icon]}
      {progress ? (
        <>
          <CircularProgress
            className="shared-icon_progress shared-icon_progress--indicator"
            variant="determinate"
            value={value}
            color="secondary"
          />
          <CircularProgress
            className="shared-icon_progress shared-icon_progress--back"
            variant="determinate"
            value={100}
          />
        </>
      ) : null}
    </div>
  );
}


export default React.memo(SharedIcon)