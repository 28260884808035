import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/company";

export const companyService={
    getAllCompanies,
    getOneCompany,
    getCompanyDetails,
    getCompanyRoomMeetings,
    deleteCompanyMeetingVideo,

    addOrUpdate
}

function getAllCompanies(){
    return fetchWrapper.get(baseUrl)
}
function getOneCompany(id){
    return fetchWrapper.get(`${baseUrl}/${id}`)
}

function getCompanyDetails(id){
    return fetchWrapper.get(`${baseUrl}/details/${id}`)
}

function getCompanyRoomMeetings(){
    return fetchWrapper.get(`${baseUrl}/room-meetings`)
}

function deleteCompanyMeetingVideo(id){
    return fetchWrapper.delete(`${baseUrl}/delete-meeting-video/${id}`)
}

function addOrUpdate(id, params, file){
    if(id)
    return fetchWrapper.postFile(`${baseUrl}/update/${id}`,file, false, params, "put").then(res=>res.data)
    else
    return fetchWrapper.postFile(`${baseUrl}/create`,file, false, params, "post").then(res=>res.data)
}