import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {Role} from '~root/_helpers'
import { accountService } from '~root/_services';

function PrivateRoute({ component: Component, roles, ...rest }) {
    return (
        <Route {...rest} render={props => {
            const user = accountService.userValue;
            if (!user) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            if(roles && roles.indexOf("authorizedOnboarder")>0 && user.role===Role.Onboarder && user.can_edit){
                return <Component {...props} />
            }
            // check if route is restricted by role
            else if (roles && roles.indexOf(user.role) === -1) {
                // role not authorized so redirect to home page
                return <Redirect to={{ pathname: '/'}} />
            }

            // authorized so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRoute };