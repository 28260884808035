import React,  { useState } from 'react'
import NotificationIcon from '~shared/components/NotificationIcon'
import {useHistory} from 'react-router-dom'

import TopbarNotificationsContainer from './TopbarNotificationsContainer';

export default function TopbarNotifications() {
    const [collapse, setCollapse] = useState(true);
    const [notificationsCount, setNotificationsCount] = useState(0);

    const history = useHistory();
    const callBack = (item) => {
        setNotificationsCount(item)
    }
    return (
        <div className="topbar__notifications">
            {!collapse && (
              <button
                type="button"
                className="topbar__back"
                onClick={() => setCollapse(!collapse)}
              />
            )}
            <div className="topbar-icon-container">
            <NotificationIcon icon="bell" onClick={() => setCollapse(!collapse)} />
                <span className="notifications-count" >{notificationsCount}</span>
            </div>
            <NotificationIcon icon="chat" onClick={()=>{
                history.push("/chat")
            }}/>
            <TopbarNotificationsContainer collapse={collapse} callBack={callBack}/>
        </div>
    )
}
