import { BehaviorSubject } from "rxjs";
import { fetchWrapper, history } from "~root/_helpers";
import {
  changeThemeToDark,
  changeThemeToLight,
} from "~root/redux/actions/themeActions";
import store from "~root/containers/App/store";

const userSubject = new BehaviorSubject(null);
const baseUrl = process.env.REACT_APP_SERVER_URL + "/accounts";

export const accountService = {
  login,
  logout,
  refreshToken,
  register,
  verifyEmail,
  forgotPassword,
  validateResetToken,
  resetPassword,
  changePassword,
  getAll,
  getById,
  create,
  update,
  getStarters,
  getGuardians,
  changeHello,
  savePreboarding,
  getPreboarding,
  getPreboardingOnbox,
  delete: _delete,
  saveAssistanceData,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
};

function login(email, password, lang) {
  return fetchWrapper
    .post(`${baseUrl}/authenticate`, { email, password, lang })
    .then((user) => {
      if (user.theme === "light") store.dispatch(changeThemeToLight());
      else if (user.theme === "dark") store.dispatch(changeThemeToDark());

      userSubject.next(user);
      startRefreshTokenTimer();
      // restore user data asistant from db
      if (user.assistent_status) {
        const assistentData = JSON.parse(user.assistent_status);

        for (const key in assistentData) {
          localStorage.setItem(key, assistentData[key]);
        }
      }
      return user;
    });
}

function changePassword(
  id,
  currentPassword,
  newPassword,
  newPasswordRepeat,
  lang
) {
  return fetchWrapper.put(`${baseUrl}/change-password`, {
    currentPassword: currentPassword,
    newPassword: newPassword,
    newPasswordConfirm: newPasswordRepeat,
    lang: lang,
  });
}

function logout(lang) {
  // save data from assisent to db
  saveAssistanceData().then(() => localStorage.clear());
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  fetchWrapper.post(`${baseUrl}/revoke-token`, { lang });
  stopRefreshTokenTimer();
  userSubject.next(null);
  history.push("/");
}

function refreshToken() {
  return fetchWrapper
    .post(`${baseUrl}/refresh-token`, {})
    .then((user) => {
      // publish user to subscribers and start timer to refresh token
      if (user.theme === "light") store.dispatch(changeThemeToLight());
      else if (user.theme === "dark") store.dispatch(changeThemeToDark());

      userSubject.next(user);
      startRefreshTokenTimer();
      return user;
    })
    .catch((err) => { });
}

function register(params, lang) {
  params.lang = lang;
  return fetchWrapper.post(`${baseUrl}/register`, params);
}

function verifyEmail(token, lang) {
  const params = { token: token, lang: lang };
  return fetchWrapper.post(`${baseUrl}/verify-email`, params);
}

function forgotPassword(email, lang) {
  const params = { email: email, lang: lang };
  return fetchWrapper.post(`${baseUrl}/forgot-password`, params);
}

function validateResetToken(token, lang) {
  const params = { token: token, lang: lang };
  return fetchWrapper.post(`${baseUrl}/validate-reset-token`, params);
}

function resetPassword({ token, password, confirmPassword }, lang) {
  return fetchWrapper.post(`${baseUrl}/reset-password`, {
    token,
    password,
    confirmPassword,
    lang,
  });
}

function getAll() {
  return fetchWrapper.get(`${baseUrl}`);
}

function getById(id, lang) {
  return fetchWrapper.get(`${baseUrl}/${id}/${lang}`);
}

function create(params, lang, file) {
  params.lang = lang;

  if (params.company === "null") params.company = undefined;

  return fetchWrapper
    .postFile(baseUrl, file, false, params, "post")
    .then((res) => res.data);
}

function update(id, params, lang, file) {
  params.lang = lang;
  if (
    id === userSubject.value.id &&
    params.role === "User" &&
    userSubject.value.role === "Admin"
  ) {
    throw new Error("error");
  }

  if (params.company === "null") params.company = undefined;

  return fetchWrapper
    .postFile(`${baseUrl}/${id}`, file, false, params, "put")
    .then((res) => {
      if (res.data.id === userSubject.value.id) {
        const user = { ...userSubject.value, ...res.data };

        if (user.theme === "light") store.dispatch(changeThemeToLight());
        else if (user.theme === "dark") store.dispatch(changeThemeToDark());

        userSubject.next(user);
      }
      return res.data;
    });
}

function changeHello(val) {
  return fetchWrapper.put(`${baseUrl}/change-hello`, { show_welcome: val });
}

function savePreboarding(items, id) {
  if (id)
    return fetchWrapper.post(`${baseUrl}/preboarding/${id}`, { items });
  else
    return fetchWrapper.post(`${baseUrl}/preboarding`, { items });
}

function getPreboarding(id) {
  if (id)
    return fetchWrapper.get(`${baseUrl}/preboarding/${id}`);
  else
    return fetchWrapper.get(`${baseUrl}/preboarding`);
}

function getPreboardingOnbox() {
    return fetchWrapper.get(`${baseUrl}/preboardingOnbox`);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    if (id === userSubject.value.id) {
      logout();
    }
    return x;
  });
}

function getStarters() {
  return fetchWrapper.get(`${baseUrl}/starters`);
}

function getGuardians() {
  return fetchWrapper.get(`${baseUrl}/guardians`);
}


// helper functions

let refreshTokenTimeout;

function startRefreshTokenTimer() {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split(".")[1]));

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - 60 * 1000;
  refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}

function saveAssistanceData(){
  // save data from assisent to db
  return fetchWrapper.post(`${baseUrl}/assistent-status`, {localStorage: localStorage});
}