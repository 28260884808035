import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/database/tasks";

export const taskService = {
    getTask,
    createTaskContents,
    createTask,
    updateTaskContents,
    deleteTask,
    update,
    toggleSharingTask,
    addStaticTask,
    updatePlanTask,

};


// #region =================================================== GET REQUESTS ========================================================

function getTask(id){
    return fetchWrapper.get(`${baseUrl}/${id}`);
}


// #endregion

//#region DELETE

function deleteTask(id){
    return fetchWrapper.delete(`${baseUrl}/${id}`);
}


//#endregion DELETE


// #region ========================================== POST REQUESTS =========================================================

function createTaskContents({taskId, content = ""}){
    return fetchWrapper.post(`${baseUrl}/create-task-contents`, {
        taskId: taskId,
        content: content
    });
}

function updateTaskContents({taskId, content}){
    return fetchWrapper.post(`${baseUrl}/update-task-contents`, {
        taskId: taskId,
        content: content
    });
}

function updatePlanTask(params) {  
    return fetchWrapper.post(`${baseUrl}/update-plan-task`, params);
  }


function createTask(params){
    return fetchWrapper.post(`${baseUrl}`, params); 
}


function update(params){
    return fetchWrapper.post(`${baseUrl}/update`, params)
}

function addStaticTask(params){
    return fetchWrapper.post(`${baseUrl}/add-static-task`, params)
}

//#endregion


//#region ========================================== PUT REQUESTS =========================================================

function toggleSharingTask(id){
    return fetchWrapper.put(`${baseUrl}/toggle-sharing/${id}`)
}

//#endregion
