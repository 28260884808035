import React from 'react'
import {Notifications, Message, Favorite} from '@material-ui/icons'

export default function NotificationIcon({icon, active=false,onClick=()=>{}}) {
    const icons={
        "bell":<Notifications/>,
        "chat" : <Message/>,
        "likes":<Favorite/>,
    }
    return (
        <div className="icon__notification" onClick={onClick}>
        {active&&<div className="notification--active"></div>}
        
            {icons[icon]}
        </div>
    )
}
