import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SharedIcon from '~shared/components/SharedIcon'
import { NavLink } from "react-router-dom";

export default class SidebarCategory extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    isNew: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    route: PropTypes.string
  };

  static defaultProps = {
    icon: '',
    isNew: false,
  };

  constructor(props) {
    super();
    this.state = {
      collapse: props.collapse,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  componentDidUpdate = () => {
    if (this.props.sidebar_collapse && this.state.collapse)
      this.setState({ collapse: false })
  }

  render() {
    const {
      title, icon, route, children,
    } = this.props;
    const { collapse } = this.state;

    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': collapse,
    });


    return (
      <div className={`${categoryClass} ${this.props.className ? this.props.className : ""}`}>
        <NavLink
          to={route}
          className="sidebar__link sidebar__category"
          onClick={(e) => { e.preventDefault(); this.toggle(); this.props.onClick() }}
          activeClassName="sidebar__link-active"
          role="button"
        >
          {icon ? <SharedIcon icon={icon} /> : ''}

          <p className="sidebar__link-title">{title}</p>
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </NavLink>
        <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
          <ul className="sidebar__submenu">
            <div>
              {children}
            </div>
          </ul>
        </Collapse>
      </div>
    );
  }
}
