import React, { Component } from "react";
import { Role } from "~root/_helpers";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarNoLink from "./SidebarNoLink";
import SidebarCategory from "./SidebarCategory";
import { accountService, alertService } from "~root/_services";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

class SidebarContent extends Component {
  constructor() {
    super();
    this.state = { starters: [], showPlanModal: false };
  }
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    //this.props.onClick();
  };

  componentDidMount = () => {
    if (
      accountService.userValue.role !== Role.Onboarder || (accountService.userValue.role === Role.Onboarder && accountService.userValue.can_edit)
    ) {
      accountService.getStarters().then((res) => {
        // get only last 3 onboarders to display on sidebar
        this.setState({ starters: res.slice(-3) });
      });
    }
  };

  showSidebar = () => {
    const { showSidebar } = this.props;
    showSidebar();
  };

  toggleModal = (val) => {
    this.setState({ showPlanModal: val });
  };

  render() {
    let children_starters = this.state.starters.map((item, index) => {
      return (
        <SidebarLink
          icon="onboarder"
          muted
          key={"sidebar_user_" + index}
          title={`${item.name} ${item.surname}`}
          route={`/starter/${item.id}`}
          onClick={this.hideSidebar}
        />
      );
    });
    {
      accountService.userValue.role !== Role.Onboarder && accountService.userValue.role !== Role.Guardian &&
        children_starters.push(
          <SidebarLink
            icon="tasks"
            muted
            key="sidebar_new_starter"
            title="Lista osób"
            route={`/team`}
            onClick={this.hideSidebar}
          />
        );
    }
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Dashboard"
            route="/"
            icon="stack"
            onClick={this.hideSidebar}
          />
        </ul>

        {accountService.userValue.role !== Role.Onboarder || accountService.userValue.can_edit ? (
          <ul className="sidebar__block">
            <SidebarCategory
              title="Zespół"
              collapse={false}
              sidebar_collapse={this.props.collapsed}
              icon="student"
              onClick={this.showSidebar}
              route="/starter"
            >
              {children_starters}
            </SidebarCategory>
          </ul>
        ) : null}

        {accountService.userValue.role === Role.Onboarder && (
          <ul className="sidebar__block">
            <SidebarLink
              exact={false}
              title="Onboarding"
              route="/task"
              icon="onboarding"
              onClick={this.hideSidebar}
            />
          </ul>
        )}
        {accountService.userValue.role === Role.Admin ||
          accountService.userValue.role === Role.Manager || (accountService.userValue.role === Role.Onboarder && accountService.userValue.can_edit) ? (
          <>
            <ul className="sidebar__block">
              <SidebarCategory
                title={"Baza"}
                collapse={false}
                sidebar_collapse={this.props.collapsed}
                icon="database"
                onClick={this.showSidebar}
                className="plan-menu-link"
                route="/database"
              >
                <SidebarLink
                  icon="tasks"
                  muted
                  key={"sidebar_database_tasks"}
                  title="Zadania"
                  route={`/database/tasks`}
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  icon="training"
                  muted
                  key={"sidebar_database_training"}
                  title="Szkolenia"
                  route={`/database/trainings`}
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  icon="meetings"
                  muted
                  key={"sidebar_database_meeting"}
                  title="Spotkania"
                  route={`/database/meetings`}
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  icon="preboarding"
                  muted
                  key={"sidebar_database_preboarding"}
                  title="Preboarding"
                  route={`/database/preboarding`}
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  icon="responsibilities"
                  muted
                  key={"sidebar_database_responsibilities"}
                  title="Obowiązki"
                  route={`/database/responsibilities`}
                  onClick={this.hideSidebar}
                />

              </SidebarCategory>

            </ul>
            <ul className="sidebar__block">
                <SidebarLink
                  title="Plany"
                  route="/planning"
                  icon="onboarding"
                  onClick={this.hideSidebar}
                />
              </ul>
            <ul className="sidebar__block">
              <SidebarLink
                exact={true}
                title="Asystent"
                route="/tutorial"
                icon="assistant"
                onClick={this.hideSidebar}
              />
            </ul>

          </>
        ) : null}
        {accountService.userValue.role !== Role.Admin && (
          <ul className="sidebar__block">
            <SidebarCategory
              title={"Spotkanie"}
              collapse={false}
              sidebar_collapse={this.props.collapsed}
              icon="team"
              onClick={this.showSidebar}
              className="plan-menu-link"
              route="/meeting"
            >
              <SidebarLink
                title="Dołącz do spotkania"
                route="/meeting"
                icon="open"
                onClick={() => {
                  window.open(
                    accountService.userValue.role === Role.Onboarder
                      ? accountService.userValue.manager.company.usr_link
                      : accountService.userValue.company.mod_link,
                    "_blank"
                  );
                  this.hideSidebar();
                }}
                new_tab={true}
              />
              <SidebarNoLink
                icon="copy"
                muted
                key={"sidebar_plan_new"}
                title="Skopiuj link"
                onClick={() => {
                  const link =
                    accountService.userValue.role === Role.Onboarder
                      ? accountService.userValue.manager.company.usr_link
                      : accountService.userValue.company.mod_link;
                  navigator.clipboard.writeText(link);
                  alertService.success("Link pomyślnie skopiowany do schowka");
                }}
                className="plan-create-link"
              />
              <SidebarLink
                  icon={"folderMeetings"}
                  muted
                  key={"sidebar_meeting_recorgings"}
                  title="Archiwum spotkań"
                  route={`/meeting/recordings`}
                  onClick={this.hideSidebar}
                />
            </SidebarCategory>
          </ul>
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation("common")(SidebarContent));
