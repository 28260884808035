import {
  CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  HIDE_SIDEBAR,
  SHOW_SIDEBAR
} from '../actions/sidebarActions';

const initialState = {
  show: true,
  collapse: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    case HIDE_SIDEBAR:
      return { ...state, collapse: true }
    case SHOW_SIDEBAR:
      return { ...state, collapse: false }
    default:
      return state;
  }
}
