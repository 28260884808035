import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/database/meetings";

export const meetingsService = {
    saveMeetings,
    getMeetings,
    getOnboxMeetings
};


function saveMeetings(items) {
    return fetchWrapper.post(baseUrl, {items});
}

function getMeetings() {
    return fetchWrapper.get(baseUrl);
}

function getOnboxMeetings() {
    return fetchWrapper.get(baseUrl + '/meetingsOnbox');
}
