import React from 'react'
import logo from "~img/logo.png";
import logotype from "~img/logotype.png";

export default React.memo(function SidebarLogo({goToMain}) {
    return (
        <div className="sidebar--small logo-container" onClick={goToMain}>
            <img src={logo} className="logo" alt="Onbox logo"/>
            <img src={logotype} className="logotype" alt="Onbox logotype"/>
        </div>
    )
}, ()=>{return true;})
