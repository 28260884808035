/* eslint-disable camelcase */
import common_en from './en/common.json';
import common_pl from './pl/common.json';
import editor_en from './en/editor.json';
import editor_pl from './pl/editor.json';
import schedule_en from './en/schedule.json';
import schedule_pl from './pl/schedule.json';
import calendar_pl from './pl/calendar.json';
import calendar_en from './en/calendar.json';

export default {
  en: {
    common: common_en,
    schedule:schedule_en,
    editor: editor_en,
    calendar:calendar_en
  },
  pl: {
    common: common_pl,
    schedule:schedule_pl,
    editor: editor_pl,
    calendar:calendar_pl
  }
};
