import React, { Component, Fragment,Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "~root/scss/app.scss";
import "react-input-checkbox/lib/react-input-checkbox.min.css";

import Router from "./Router";
import store from "./store";
import ScrollToTop from "./ScrollToTop";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { config as i18nextConfig } from "~root/translations";
import { history } from "~root/_helpers/history";
import Loading from "~shared/components/Loading";
import Chat from "~shared/components/RocketChat/ChatCollapse"
import { accountService } from "~root/_services";

i18n.init(i18nextConfig);

class App extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      showChat: false
    };
  }

  componentDidMount = () => {
    const this_ref = this
    accountService.user.subscribe({
      next(user) {
        this_ref.setState({ showChat: user && user.rocketToken })
      }
    })
  }
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <ScrollToTop>
              <Fragment>
                <div>
                  {this.state.showChat && <Chat />}
                  <Suspense fallback={<Loading />}>
                    <Router history={history} />
                  </Suspense>
                </div>
              </Fragment>
            </ScrollToTop>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
