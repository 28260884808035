import React, { Component } from "react";
import { withTranslation } from "react-i18next";
//import './cart-dropdown.styles.scss';
import { notificationService } from '~root/_services'

import moment from 'moment'
import 'moment/locale/pl';
moment.locale('pl')

const Ava = `${process.env.PUBLIC_URL}/img/avatar3.png`;


class TopbarNotificationsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: []
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  componentDidMount() {

    notificationService.getNotifications().then(res => {
      this.setState({ notifications: res });
      this.props.callBack(res.length);
    })

    setInterval(() => {
      notificationService.getNotifications().then(res => {
        this.setState({ notifications: res });
        this.props.callBack(res.length);
        
      })
    }, 1000*15);

  }
  
  render() {
    const { notifications } = this.state;

    return (
      <>
        {!this.props.collapse && (
          <div className='notifications-dropdown'>
            <div className='notifications-items'>
              {notifications.length > 0 ? notifications.map((item) => (
                <div className='notifications-item' onClick={() => {
                  notificationService.setNotificationAsReaded(item.id).then(res => {
                    notificationService.getNotifications().then(res => {this.setState({ notifications: res }); this.props.callBack(res.length);})
                  }
                  ).catch(err => console.log(err));
                  }}>
                <img src={Ava} alt='item' />
                <div className='item-details'>
                  <span className='price'>
                    {item.fromUserName} {item.fromUserSurname}
                    <br></br>
                  </span>
                  <span className='name'>{item.title}</span>
                  <span className='price'>{moment(item.createdAt).fromNow()}</span>

                  
                </div>
              </div>
              )
              ) : <span className="mt-5 px-5">Brak powiadomień. Powiadomienia otrzymasz gdy twoi onboardowani ukończą onboarding</span>}
            </div>
          </div>)}
      </>
    );
  }
}
export default withTranslation("common")(TopbarNotificationsContainer);
