import React from 'react'
import ChatFrame from './components/ChatFrame';
import SideCollapse from '../SideCollapse';
export default function ChatCollapse() {
    return (
        <SideCollapse iconClass="icon-chat" icon="message" contentClass="chat-container" mainContainerClass="chat">
            <ChatFrame />
        </SideCollapse>
    )
}
