import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/database/surveys";

export const surveyService = {
    create,
    update,
    get,
    saveAnswers
};

/* #region  POST */

function create({ questions, survey }) {
    return fetchWrapper.post(`${baseUrl}/create`, { questions: questions, survey: survey });
}


function update({ questions, survey }, id) {
    return fetchWrapper.post(`${baseUrl}/update`, { questions: questions, survey: survey, id: id });
}

function saveAnswers({day_id, answers}){
    return fetchWrapper.post(`${baseUrl}/save-answers`, {day_id: day_id, answers:answers});
}

/* #endregion */


/* #region  GET */
function get(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}




/* #endregion */
