import React, { PureComponent } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import Collapse from "react-bootstrap/Collapse";
import { accountService } from "~root/_services";
import { withTranslation } from "react-i18next";
import { Role } from "~root/_helpers";
import TopbarMenuLink from "./TopbarMenuLink";

const Ava = `${process.env.PUBLIC_URL}/img/avatar3.png`;

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  render() {
    const user = accountService.userValue;
    // const { collapse } = this.state;

    return (
      <>
        {user ? (
          <div className="topbar__profile">
            <button
              type="button"
              className="topbar__avatar"
              onClick={this.toggle}
            >
              <img className="topbar__avatar-img" src={user.avatar?process.env.REACT_APP_SERVER_URL + "/file/get/"+user.avatar:Ava} alt="avatar" />

              <p className="topbar__avatar-name">{`${user.name?user.name:""} ${user.surname?user.surname:""}`}</p>
              <DownIcon className="topbar__icon" />
            </button>

            {this.state.collapse && (
              <button
                type="button"
                className="topbar__back"
                onClick={this.toggle}
              />
            )}
            <Collapse in={this.state.collapse} className="topbar__menu-wrap">
              <div className="topbar__menu">
                {user && user.role === Role.Admin ? (
                  <>
                  <TopbarMenuLink
                    path="/companies"
                    icon="apartment"
                    title="Firmy"
                    onClick={this.toggle}
                  />
                   <TopbarMenuLink
                    path="/instructions"
                    icon="question-circle"
                    title="Instrukcje"
                    onClick={this.toggle}
                  />
                  </>
                ) : null}
                
                <TopbarMenuLink
                    path="/hello"
                    icon="question-circle"
                    title="Filmiki"
                    onClick={this.toggle}
                    state={{reminder:true}}
                  />

                {user &&
                (user.role === Role.Admin || user.role === Role.Manager) ? (
                  <TopbarMenuLink
                    path="/adminUsers"
                    icon="users"
                    title={this.props.t("layout.users")}
                    onClick={this.toggle}
                  />
                ) : null}
                <TopbarMenuLink
                  path="/changePassword"
                  icon="cog"
                  title={this.props.t("layout.change-passw")}
                  onClick={this.toggle}
                />
                <TopbarMenuLink
                  path="/"
                  icon="exit"
                  title={this.props.t("layout.log-out")}
                  onClick={() => {
                    accountService.logout(this.props.i18n.language);
                  }}
                />
              </div>
            </Collapse>
          </div>
        ) : null}
      </>
    );
  }
}
export default withTranslation("common")(TopbarProfile);
