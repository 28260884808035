import React, { useImperativeHandle, useState, useEffect } from 'react'
import SharedIcon from './SharedIcon';

const SideCollapse = React.forwardRef(({ children, icon, iconClass, contentClass, mainContainerClass, unmountedChanged=()=>{}, setCollapsed=true}, ref) => {
    const [collapsed, toggle] = useState(setCollapsed);
    const [unmounted, toggleUnmount] = useState(true)

    const toogleCollapsed = () => {toggle(!collapsed)}
    
    useImperativeHandle(ref, () => ({
        unmounted: unmounted,
        toggleUnmount: toggleUnmount,
        collapsed: collapsed,
        toogleCollapsed,
    }));

    useEffect(() => {
        unmountedChanged(unmounted)
    }, [unmounted])

    return (
        <div
            className={`collapsed-container ${collapsed ? "collapsed" : "open"} ${mainContainerClass}`}
            onTransitionEnd={() => {
                if (collapsed)
                    toggleUnmount(true)
            }}
        >
            <div
                className={`icon-container ${iconClass}`}
                onClick={() => {
                    if (collapsed)
                        toggleUnmount(false)

                    toggle((val) => !val);
                }}
            >
                <SharedIcon active icon={icon} />
            </div>

            <div className={contentClass}>
                {children}
            </div>
        </div>
    )
})

export default SideCollapse