import {
    SET_INSTRUCTIONS
  } from '../actions/instructionActions';
  
  const initialState = [];
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case SET_INSTRUCTIONS:
        return action.val;
      default:
        return state;
    }
  }
  