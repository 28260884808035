import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/database/trainings";

export const trainingsService = {
    saveTrainings,
    createTrainingFromMeeting,
    
    getTrainings,
    getOnboxTrainings,
};


function saveTrainings(items) {
    return fetchWrapper.post(baseUrl, {items});
}

function createTrainingFromMeeting(videoLink, date) {
    return fetchWrapper.post(`${baseUrl}/create-training`, {videoLink, date});
}

function getTrainings() {
    return fetchWrapper.get(baseUrl);
}

function getOnboxTrainings() {
    return fetchWrapper.get(baseUrl + '/trainingsOnbox');
}
