import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import Topbar from "./topbar/Topbar";
import TopbarLoginPage from "./topbar_login/Topbar";

import Sidebar from "./sidebar/Sidebar";
import SidebarLarge from "./sidebar_large/Sidebar";
import SidebarButton from "./sidebar/SidebarButton";
import {
  changeThemeToDark,
  changeThemeToLight,
} from "~root/redux/actions/themeActions";
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  hideSidebar,
  showSidebar,
} from "~root/redux/actions/sidebarActions";
import {changeModalVisibility} from '~root/redux/actions/demoActions'
import { SidebarProps } from "~root/shared/prop-types/ReducerProps";
import { accountService } from "~root/_services";

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
  };

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeModalVisibility = (val) => {
    const { dispatch } = this.props;
    dispatch(changeModalVisibility(val));
  };


  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  uncollapseSidebar = () => {
    const { dispatch } = this.props;
    dispatch(showSidebar());
  };

  collapseSidebar = () => {
    const { dispatch } = this.props;
    dispatch(hideSidebar());
  };

  render() {
    const { sidebar } = this.props;
    const layoutClass = classNames({
      layout: true,
      "layout--collapse": accountService.userValue && sidebar.collapse,
      "layout--login": this.props.sidebarType === "large",
      "layout--without-topbar": !this.props.withTopBar,
    });

    return (
      <>
        <div className={layoutClass}>
          {accountService.userValue ? (
            this.props.withTopBar ? (
              <Topbar
                changeMobileSidebarVisibility={
                  this.changeMobileSidebarVisibility
                }
                changeSidebarVisibility={this.changeSidebarVisibility}
              />
            ) : null
          ) : (
            <TopbarLoginPage />
          )}
          {this.props.sidebarType === "small" ? (
            accountService.userValue ? (
              <>
                <Sidebar
                  goToMain={() => {
                    this.props.history.push("/");
                  }}
                  sidebar={sidebar}
                  changeToDark={this.changeToDark}
                  changeToLight={this.changeToLight}
                  changeMobileSidebarVisibility={
                    this.changeMobileSidebarVisibility
                  }
                  changeSidebarVisibility={this.changeSidebarVisibility}
                  showSidebar={this.uncollapseSidebar}
                  hideSidebar={this.collapseSidebar}
                  changeModalVisibility={this.changeModalVisibility}
                />
                <SidebarButton
                  onClick={this.changeSidebarVisibility}
                  collapsed={sidebar.collapse}
                />
              </>
            ) : null
          ) : (
            <SidebarLarge />
          )}
        </div>
      </>
    );
  }
}

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
  }))(Layout)
);
