import React from "react";
import Scrollbar from "smooth-scrollbar-react";
import LogoContainer from "./LogoContainer";
import Footer from "./Footer";
import {accountService} from '~root/_services'
const Sidebar = () => {
  return (
    <div className="sidebar sidebar--login sidebar--show">
      <Scrollbar className="sidebar__scroll scroll scroll-login">
        <div className="sidebar__wrapper">
          <div className="h-100 d-flex flex-column justify-content-between">
            <div className="logo-container text-center">
              <div style={{marginBottom:"50px", color:"#dddddd"}}>
              <h1 className="logo-greetings">
              {`Witaj${accountService.userValue && accountService.userValue.name?`, ${accountService.userValue.name}`:""}!`}
              </h1>
              </div>
              <LogoContainer />
            </div>
            <Footer />
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};

export default Sidebar;
