import { combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { sidebarReducer, themeReducer, rtlReducer, colorReducer, demoReducer, instructionReducer } from '~root/redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  color: colorReducer,
  demo:demoReducer,
  instructions:instructionReducer
});

const store = createStore(reducer);

export default store;
