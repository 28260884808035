import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/onboarding";

export const onboardService = {
    //IDK IF NEW OR NOT

    getTaskContents,
    updateTask,

    //NEW
    getFreePlans,
    getUserPlan,
    getCurrentTaskID,
    getTaskDetails,
    getCategoryProgress,
    getOnboardersProgress,
    getUserProgress,

    startOnboarding,
    saveTaskStatus,
    saveTaskUserData,
    updateTaskComment
};

//#region OLD

// POST REQUESTS

function updateTask({ taskId, content, time, multiple_select }) {
    return fetchWrapper.post(`${baseUrl}/updateTask`, { _id: taskId, content: content, time: time, multiple_select: multiple_select });
}


// GET REQUESTS

function getTaskContents(taskId) {
    return fetchWrapper.get(`${baseUrl}/getTaskContents/${taskId}`);
}
function getCurrentTaskID(taskId) {
    return fetchWrapper.get(`${baseUrl}/current-task`);
}

function getTaskDetails(id, state){
    const preview = state && state.preview
    const link = preview ?`${baseUrl}/task-details/${id}/preview`:`${baseUrl}/task-details/${id}`
    return fetchWrapper.get(link);
}

function getCategoryProgress(id){
    return fetchWrapper.get(`${baseUrl}/category-progress/${id}`);
}

function getOnboardersProgress(){
    return fetchWrapper.get(`${baseUrl}/onboarders-progress`);
}

// DELETE REQUESTS


// PUT REQUESTS

function startOnboarding(id){
    return fetchWrapper.put(`${baseUrl}/start-onboarding/${id}`);
}

function saveTaskStatus(id, status){
    return fetchWrapper.put(`${baseUrl}/save-task-status/${id}`, {status:status});
}

function saveTaskUserData(id, data){
    return fetchWrapper.put(`${baseUrl}/update-task-user-data/${id}`, {comments: data.comments, progress: data.progress, progress_percentage: data.progress_percentage, data:data.content, video_progress:data.video_progress, isFinished:data.isFinished});
}

function updateTaskComment(task_id, comment_id, comment){
    return fetchWrapper.put(`${baseUrl}/update-task-comment/${task_id}`, {comment: comment, comment_id: comment_id});
}

//#endregion OLD

//#region NEW

function getFreePlans(){
    return fetchWrapper.get(`${baseUrl}/free-plans`)
}

function getUserPlan(id){
    return fetchWrapper.get(`${baseUrl}/user-plan/${id}`)
}

function getUserProgress(id){
    return fetchWrapper.get(`${baseUrl}/user-progress/${id}`)
}
//#endregion NEW