import React, {useState,useEffect} from 'react'
import SharedIcon from '~shared/components/SharedIcon'
import avatar from '~img/avatar3.png'
import {accountService} from '~root/_services'
export default function Mentor() {
    const [manager, setManager] = useState({})
    useEffect(() => {
        setManager(accountService.userValue.guardian?accountService.userValue.guardian:accountService.userValue.manager)
    },[])

    if(!!manager)
    return (
        <div className="mentor-container">
            <h3>Twój opiekun</h3>
            <img src={manager.avatar?process.env.REACT_APP_SERVER_URL + "/file/get/"+manager.avatar:avatar} alt="Mentor" className="user--avatar lg"/>
            {(manager.name || manager.surname) && <span>{`${manager.name?manager.name:""} ${manager.surname?manager.surname:""}`}</span>}
            {manager.email && <span>{`${manager.email?manager.email:""}`}</span>}
            {manager.phone && <span>{`${manager.phone?manager.phone:""}`}</span>}
            {manager.available_hours &&<span>{`Godziny dostępności: ${manager.available_hours}`}</span>}
            {manager.preferred_contact &&<span>{`Preferowana forma kontaktu: ${manager.preferred_contact}`}</span>}
            <div className="icon-container">
        <SharedIcon icon="phone" active/><SharedIcon icon="pin" active/>
            </div>
        </div>
    )
    else return null;
}
