import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import { withTranslation } from "react-i18next";
import SharedIcon from "~shared/components/SharedIcon";

const SidebarNoLink = ({
  title,
  icon = "stack",
  newLink,
  muted = false,
  onClick,
  className=""
}) => {

  return (
      <li className={`sidebar__link ${className}`} onClick={onClick}>
        {icon ? <SharedIcon icon={icon} muted={muted} /> : ""}
        <p className="sidebar__link-title">
          {title}
          {newLink ? (
            <Badge className="sidebar__link-badge">
              <span>nowe</span>
            </Badge>
          ) : (
            ""
          )}
        </p>
      </li>
  );
};


SidebarNoLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  onClick: PropTypes.func,
};

SidebarNoLink.defaultProps = {
  icon: "",
  newLink: false,
  onClick: () => {},
};

export default withTranslation("common")(SidebarNoLink);
