import React, {lazy, useEffect, useState, useCallback} from 'react'
import { Route } from "react-router-dom";
import Layout from "~root/containers/Layout/index";

import Instruction from "~shared/components/Instruction";

import { matchPath } from 'react-router';
import {connect} from 'react-redux'

const TaskEditor = lazy(()=>import("./Tasks/Common/TaskEditor"))
const Tasks = lazy(()=>import("./Tasks"))
const Meetings = lazy(()=>import("./Meetings"))
const Trainings = lazy(()=>import("./Trainings"))
const Preboarding = lazy(()=>import("./Preboarding"))
const Responsibilities = lazy(()=>import("./Responsibilities"))


function DatabaseRouteWrapper({location, instructions}) {
  const [instruction, setInstruction] = useState()

  const findInstruction = useCallback(()=>{
    const currentRoute = routeNames.find(
      route => matchPath(location.pathname, {path:route.name, exact:true})
    )
  
    const tmp_instruction = instructions.find(item=>item.route===currentRoute.name)
    setInstruction(tmp_instruction)
  },[instructions, location.pathname]);

 useEffect(() => {
  const currentRoute = routeNames.find(
    route => matchPath(location.pathname, {path:route.name, exact:true})
  )
  if(currentRoute)
    findInstruction()
  }, [instructions, location, findInstruction])

  return (
    <div>
      {instruction && <Instruction src={instruction}/>}
      <Layout sidebarType="small" withTopBar />
      <div className="container__wrap">
        <Route path={`/database/edit-task/:id`} component={TaskEditor} />

        <Route exact path="/database/tasks" component={Tasks} />
        <Route exact path="/database/meetings" component={Meetings} />
        <Route exact path="/database/trainings" component={Trainings} />
        <Route exact path="/database/preboarding" component={Preboarding} />
        <Route exact path="/database/responsibilities" component={Responsibilities} />
      </div>
    </div>
  )
}

const mapStateToProps = (state)=>{
  return {instructions:state.instructions}
}

export default connect(mapStateToProps)(DatabaseRouteWrapper)

export const routeNames = [
  {name:"/database/edit-task/:id", details:"Widok edycji treści zadania"}, 
  {name:"/database/tasks", details:"Widok zadań i kategorii"},
  {name:"/database/trainings", details:"Widok szkoleń"},
  {name:"/database/meetings", details:"Widok spotkań"},
]