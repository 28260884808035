import React from "react";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <span>Onbox system Admin Dashboard</span>
        <span>&copy; 2020 All Rights Reserved</span>
        <span>Made with love by HXS</span>
      </div>
      <div className="footer--small">
        <span>&copy; 2020</span>
      </div>
    </>
  );
}
