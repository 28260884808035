import React, { lazy, useEffect, useState, useCallback } from "react";
import { Route } from "react-router-dom";
import Layout from "~root/containers/Layout/index";
import { accountService } from "~root/_services";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import Instruction from "~shared/components/Instruction";

const Dashboard = lazy(()=>import("./Dashboard"))

const ManagerRouteWrapper = ({ demo, location, instructions }) => {
  const [instruction, setInstruction] = useState();

  const findInstruction = useCallback(() => {
    const currentRoute = routeNames.find((route) =>
      matchPath(location.pathname, { path: route.name, exact: true })
    );

    const tmp_instruction = instructions.find(
      (item) => item.route === currentRoute.name
    );
    setInstruction(tmp_instruction);
  }, [instructions, location.pathname]);

  useEffect(() => {
    const currentRoute = routeNames.find((route) =>
      matchPath(location.pathname, { path: route.name, exact: true })
    );
    if (currentRoute) findInstruction();
  }, [instructions, location, findInstruction]);



  return (
    <div>
      {instruction && <Instruction src={instruction} />}

      <Layout sidebarType="small" withTopBar />
      <div className="container__wrap">
        <div className="" style={{ padding: 20, paddingTop: 0 }}>
          <h2>{`Witaj${
            accountService.userValue.name
              ? `, ${accountService.userValue.name}`
              : ""
          }!`}</h2>
        </div>
          <Route
            exact
            path="/"
            component={() => {
              return <Dashboard demo={demo} />;
            }}
          />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    demo: state.demo.demo,
    instructions: state.instructions,
  };
};

export default connect(mapStateToProps)(ManagerRouteWrapper);

export const routeNames = [{ name: "/", details: "Dashboard admina/managera" }];
