export const CHANGE_MODAL_VISIBILITY = 'CHANGE_MODAL_VISIBILITY';
export const TOGGLE_DEMO = 'TOGGLE_DEMO';


export function changeModalVisibility() {
  return {
    type: CHANGE_MODAL_VISIBILITY
  };
}

export function toggleDemo(val) {
  return {
    type: TOGGLE_DEMO,
    value:val
  };
}
