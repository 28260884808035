import React, { lazy, useEffect, useState, useCallback } from 'react'
import { Route } from "react-router-dom";
import { accountService, alertService, onboardService } from "~root/_services";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import Instruction from "~shared/components/Instruction";

const Dashboard = lazy(()=>import("./Dashboard"));
const Layout = lazy(()=>import("~root/containers/Layout/index"));
const TaskPage = lazy(()=>import("./TaskPage"));
const TestPage = lazy(()=>import('./TestPage'));

function OnboarderRouteWrapper({ demo, history, location, instructions }) {
  const [instruction, setInstruction] = useState()

  const findInstruction = useCallback(() => {
    const currentRoute = routeNames.find(
      route => matchPath(location.pathname, { path: route.name, exact: true })
    )


    const tmp_instruction = instructions.find(item => item.route === currentRoute.name)
    setInstruction(tmp_instruction)
  },[instructions, location.pathname])

  useEffect(() => {
    const currentRoute = routeNames.find(
      route => matchPath(location.pathname, { path: route.name, exact: true })
    )

    if (currentRoute)
      findInstruction()
  }, [instructions, location, findInstruction])


  return (
    <div>
      {instruction && <Instruction src={instruction} />}

      <Layout sidebarType="small" withTopBar />
      <div className="container__wrap">
        <div className="" style={{ padding: 20, paddingTop: 0 }}>
          <h2>{`Witaj${accountService.userValue.name
              ? `, ${accountService.userValue.name}`
              : ""
            }!`}</h2>
        </div>
        <Route exact path="/task/:id" component={TaskPage} />
        <Route exact path="/test/:id" component={TestPage} />

        <Route
          exact
          path="/task"
          component={() => {
            return <GetLink history={history} />;
          }}
        />
        <Route
          exact
          path="/"
          component={() => {
            return <Dashboard demo={demo} />;
          }}
        />
      </div>
    </div>
  );
}

const GetLink = ({ history }) => {
  onboardService
    .getCurrentTaskID()
    .then((res) => {
      history.push("/task/" + res);
    })
    .catch((err) => {
      history.push("/");
      alertService.error(err, { keepAfterRouteChange: true });
    });

  return null;
};

const mapStateToProps = (state) => {
  return {
    demo: state.demo.demo,
    instructions: state.instructions,
  };
};

export default connect(mapStateToProps)(OnboarderRouteWrapper);

export const routeNames = [
  { name: "/task/:id", details: "Widok zadania dla onboardowanego/podgląd" },
  { name: "/test/:id", details: "Widok testu" },
  {
    name: "/task",
    details:
      "Link tylko do pobrania aktualnego dnia, roboczy, nie pokazuje się użytkownikowi",
  },
  { name: "/", details: "Dashboard onboardowanego" },
];
