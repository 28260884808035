import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SharedIcon from "~shared/components/SharedIcon";

const SidebarLink = ({
  title,
  icon = "stack",
  newLink,
  route,
  exact = true,
  onClick,
  muted = false,
  withoutClass = false,
  className = ""
}) => {

  return (
    <NavLink
      className={className}
      to={route}
      exact={exact}
      onClick={onClick}
      activeClassName={withoutClass ? "" : "sidebar__link-active"}
    >
      <li className="sidebar__link">
        {icon ? <SharedIcon icon={icon} muted={muted} /> : ""}
        <p className="sidebar__link-title">
          {title}
          {newLink ? (
            <Badge className="sidebar__link-badge">
              <span>nowe</span>
            </Badge>
          ) : (
            ""
          )}
        </p>
      </li>
    </NavLink>
  );
};


SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: "",
  newLink: false,
  route: "/",
  onClick: () => { },
};

export default withTranslation("common")(SidebarLink);
