import React, { useRef, useEffect } from 'react'
import { accountService } from '~root/_services'


export default function ChatFrame() {
    const chatRef = useRef()
    const chatLoaded = () => {
        chatRef.current.contentWindow.postMessage({
            externalCommand: 'login-with-token',
            token: accountService.userValue.rocketToken
        }, '*')
    }

    useEffect(() => {
        return () => {
            chatRef.current.contentWindow.postMessage({
                externalCommand: 'logout'
            }, '*')
        }
    }, [])

    return (
        <iframe
            src="https://chat.onbox.pl"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            ref={chatRef}
            onLoad={chatLoaded}
        />
    )
}
