import React from 'react'

export default function Footer() {
    return (
        <div className="footer-container">
        <span className="footer-title lighter">
          Innowacyjna platforma do onboardowania pracowników w Twojej firmie.
        </span>
        <div className="line"></div>
        <span className="footer-title darker bold">Onbox system Admin Dashboard</span>
        <span  className="footer-title darker">&copy; 2021 All Rights Reserved</span>
      </div>
    )
}
