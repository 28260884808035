//#region imports
import React, { useState, useEffect, lazy } from "react";

import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { PrivateRoute } from "~root/containers/PrivateRoute";
import { matchPath } from 'react-router';

import { Role } from "~root/_helpers";
import { accountService, fileService, alertService } from "~root/_services";
import { Alert } from "~root/containers/Alert";

import { setInstructions } from "~root/redux/actions/instructionActions";
import { connect } from 'react-redux'

import Layout from "~root/containers/Layout/index";
import MainWrapper from "./MainWrapper";
import OnboarderRouteWrapper from "~root/containers/Onboarder/OnboarderRouteWrapper"
import ManagerRouteWrapper from "~root/containers/Manager/ManagerRouteWrapper"
import DatabaseRouteWrapper from "../Database/DatabaseRouteWrapper"
import MeetingRouteWrapper from "../Meeting/MeetingRouteWrapper"

const LogIn = lazy(() => import("~root/containers/accounts/LogIn/index"))
const resetPassw = lazy(() => import("~root/containers/accounts/resetPassw/index"))
const forgetPassw = lazy(() => import("~root/containers/accounts/forgetPassw/index"))
const verifyEmail = lazy(() => import("~root/containers/accounts/verifyEmail/index"))
const AdminUsers = lazy(() => import("~root/containers/admin/index"))
const TeamUsers = lazy(() => import("~root/containers/Team/index"))
const ChangePassword = lazy(() => import("~root/containers/accounts/ChangePassword"))
const PlanningRouteWrapper = lazy(() => import("~root/containers/Planning/PlanningRouteWrapper"))
const Calendar = lazy(() => import("~root/containers/OnboardersList/Profile"))
const HelloPage = lazy(() => import("~root/containers/HelloPage"))
const CompanyWrapper = lazy(() => import("~root/containers/Company"))
const Demo = lazy(() => import("~root/containers/Demo"))
const Instructions = lazy(() => import("~root/containers/Instructions"))
const Tutorial = lazy(() => import("~root/containers/Tutorial"))
const Instruction = lazy(() => import("~shared/components/Instruction"))
const ChatFrame = lazy(() => import("~shared/components/RocketChat/components/ChatFrame"))
const PreboardingCollapse = lazy(() => import("~shared/components/PreboardingCollapse"))

//#endregion

const mapStateToProps = (state) => {
  return {
    instructions: state.instructions
  }

}

const authorizedOnboarder = "authorizedOnboarder"



const WrappedRoutes = connect(mapStateToProps)(({ sidebarType = "small", withTopBar = true, instructions, withContainer = true }) => {
  const location = useLocation();

  const currentRoute = ROUTES.find(
    route => matchPath(location.pathname, route.path)
  )

  const instruction = instructions.find(instr => instr.route === currentRoute.path)


  return (
    <div>
      {instruction && <Instruction src={instruction} />}
      <Layout sidebarType={sidebarType} withTopBar={withTopBar} />

      <Route path="/chat" component={() => (
        <div className="big-chat-container">
          <ChatFrame />
        </div>
      )} />

      {withContainer &&
        <div className="container__wrap">
          <Route path="/login" component={LogIn} />
          <Route path="/forgetPassword" component={forgetPassw} />
          <Route path="/verifyEmail" component={verifyEmail} />
          <Route exact path="/resetPassword" component={resetPassw} />

          <Route path="/hello" component={HelloPage} />
          <Route path="/demo" component={Demo} />
          <Route path="/tutorial" component={Tutorial} />

          <Route
            path="/adminUsers"
            roles={[Role.Admin, Role.Manager]}
            component={AdminUsers}
          />
          <Route
            path="/team"
            roles={[Role.Admin, Role.Manager]}
            component={TeamUsers}
          />
          <Route path="/changePassword" component={ChangePassword} />
          <Route path="/companies" component={CompanyWrapper} />

          <Route
            path="/starter/:id"
            exact
            roles={[Role.Admin, Role.Manager, Role.Guardian, authorizedOnboarder]}
            component={Calendar}
          />
          <Route
            path="/instructions"
            roles={[Role.Admin]}
            component={Instructions}
          />
        </div>
      }
    </div>

  );
});

const wrappedRoutesContainer = (props) => {
  return <WrappedRoutes {...props} />
}

const ROUTES = [
  {
    path: "/login",
    exact: true,
    component: () => {
      return wrappedRoutesContainer({
        sidebarType: "large",
      });
    },
    details: "Strona logowania"
  },
  {
    path: "/hello",
    exact: true,
    component: () => {
      return wrappedRoutesContainer({
        sidebarType: "large",
        withTopBar: false,
      });
    },
    private: true,
    details: "Strona z filmikiem powitalnym"
  },
  {
    path: "/demo",
    component: () => {
      return wrappedRoutesContainer({
        sidebarType: "large",
        withTopBar: false,
      });
    },
    roles: [Role.Manager, Role.Admin, Role.Guardian, authorizedOnboarder],
    exact: true,
    private: true,
    details: "Strona z filmikiem wprowadzającym i przejściem do demo"
  },
  {
    path: "/tutorial",
    component: wrappedRoutesContainer,
    roles: [Role.Manager, Role.Admin, Role.Guardian, authorizedOnboarder],
    exact: true,
    private: true,
    details: "Samouczek tworzenia planu"
  },
  {
    path: "/task",
    component: OnboarderRouteWrapper,
    private: true,
    details: "Link przekierowujący do drugiego /task, roboczy"
  },
  {
    path: "/chat",
    component: () => {
      return wrappedRoutesContainer({ withContainer: false })
    },
    private: true,
    details: "Widok czatu pełnoekranowego"
  },
  {
    path: "/test/:id",
    component: OnboarderRouteWrapper,
    private: true,
    details: "Link przekierowujący do testu, roboczy"
  },
  {
    path: "/resetPassword",
    component: () => {
      return wrappedRoutesContainer({ sidebarType: "large" });
    },
    details: "Podstrona resetowania hasła"
  },
  {
    path: "/verifyEmail",
    component: () => {
      return wrappedRoutesContainer({ sidebarType: "large" });
    },
    details: "Weryfikacja adresu e-mail"
  },
  {
    path: "/forgetPassword",
    exact: true,
    component: () => {
      return wrappedRoutesContainer({ sidebarType: "large" });
    },
    details: "Uzupełnienie maila, gdy hasło zostało zapomniane"
  },
  {
    path: "/changePassword",
    component: wrappedRoutesContainer,
    details: "Zmiana hasła"
  },
  {
    path: "/companies",
    roles: [Role.Admin],
    component: wrappedRoutesContainer,
    private: true,
    details: "Lista firm"
  },
  {
    path: "/adminUsers",
    roles: [Role.Admin, Role.Manager],
    component: wrappedRoutesContainer,
    private: true,
    details: "Zarządzanie użytkownikami"
  },
  {
    path: "/team",
    roles: [Role.Admin, Role.Manager],
    component: wrappedRoutesContainer,
    private: true,
    details: "Zarządzanie zespołem"
  },
  {
    path: "/planning",
    roles: [Role.Manager, Role.Admin, authorizedOnboarder],
    component: PlanningRouteWrapper,
    private: true,
    details: "Przekierowanie do podstrony planowania"
  },
  {
    path: "/starter/:id",
    roles: [Role.Admin, Role.Manager, Role.Guardian, authorizedOnboarder],
    component: (props) => {
      return wrappedRoutesContainer({
        key: props.match.params.id, //PASSING KEY PROP TO TRIGGER RE-RENDER OF COMPONENT
        ...props
      });
    },
    private: true,
    exact: true,
    details: "Podstrona onboardowanego"
  },
  {
    path: "/database",
    roles: [Role.Admin, Role.Manager, authorizedOnboarder],
    component: DatabaseRouteWrapper,
    private: true,
    details: "Przekierowanie do podstrony bazy"
  },
  {
    path: "/meeting",
    roles: [Role.Admin, Role.Manager],
    component: MeetingRouteWrapper,
    private: true,
    details: "Przekierowanie do podstrony spotkania"
  },
  {
    path: "/instructions",
    roles: [Role.Admin],
    component: wrappedRoutesContainer,
    private: true,
    details: "Podstrona dodawania instrukcji"
  }
];

const Router = (props) => {
  const { pathname } = useLocation();
  const [user, setUser] = useState({});
  const [loadedApp, setLoadedApp] = useState(false);
  useEffect(() => {
    const subscription = accountService.user.subscribe((x) => setUser(x));
    getInstructions();
    setLoadedApp(true);
    return subscription.unsubscribe;
  }, []);

  const getInstructions = () => {
    fileService.getInstructions()
    .then(res => props.dispatch(setInstructions(res)))
    .catch(() => alertService.error("Błąd w załadowaniu pliku PDF"))
  }

  const all_routes = ROUTES.map((item, index) => {
    if (item.private)
      return (
        <PrivateRoute
          path={item.path}
          component={item.component}
          exact={item.exact}
          roles={item.roles}
          key={`route_${index}`}
        />
      );
    else
      return (
        <Route path={item.path} component={item.component} exact={item.exact} key={`route_${index}`} />
      );
  });

  const withPreboarding = accountService.userValue && accountService.userValue.role === Role.Onboarder && accountService.userValue.preboarding


  return (
    <MainWrapper>
      <main>
        <Alert />
        {withPreboarding && <PreboardingCollapse list={accountService.userValue.preboarding} />}

        {loadedApp && (
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

            {user ? (
              <Redirect from="/login" to={user.show_welcome ? "/hello" : "/"} />
            ) : null}

            {all_routes}

            {user ? (
              user.role === Role.Manager || user.role === Role.Guardian || user.role === Role.Admin ? (
                <PrivateRoute
                  exact
                  path="/"
                  roles={[Role.Manager, Role.Admin, Role.Guardian]}
                  component={ManagerRouteWrapper}
                />
              ) : (
                <PrivateRoute
                  exact
                  path="/"
                  roles={[Role.Onboarder]}
                  component={OnboarderRouteWrapper}
                />
              )
            ) : (
              <Redirect from="/" to="/login" />
            )}

            <Redirect from="*" to="/" />
          </Switch>
        )}
      </main>
    </MainWrapper>
  );
};


export default connect(mapStateToProps)(Router);


export const getRouteNames = () => {
  return ROUTES.map((item) => {
    return { name: item.path, details: item.details }
  })
}