
import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/file";

const route = (name) => `${baseUrl}/${name}`;

export const fileService = {
    upload,
    uploadInstruction,
    getInstructions,
    uploadTrainingVideo,
    deleteTrainingVideos,
};



//#region                              =============  POST ==============


function upload(files) {


    return fetchWrapper.postFiles(route('upload'),files,false);


}

function uploadInstruction(file, route){
    return fetchWrapper.postFile(`${baseUrl}/instruction`,file, false, {route:route}, "post").then(res=>res.data)
}

function getInstructions(){
    return fetchWrapper.get(`${baseUrl}/instructions`)
}

function uploadTrainingVideo(id, file, videoId) {

    return fetchWrapper.postFile(`${baseUrl}/trainingsVideo`,file, false, { videoId: videoId, id: id }, "post").then(res=>res.data)

}

//#region                              =============  PUT ==============





//#endregion

//#region                              =============  DELETE ==============
 

function deleteTrainingVideos(items) {
    return fetchWrapper.deleteFiles(`${baseUrl}/trainingsVideo`, {items: items});

}

//#endregion