import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL + "/database/categories";

export const categoryService = {
    getAllCategories,
    getAllUser,
    getAllOnbox,
    getBaseSummary,
    getTutorialMaterials,
    
    createCategory,
    updateDatabase,
    toggleSharingCategory,

    updateCategoryDetails,

    deleteCategory
};


// #region =================================================== GET REQUESTS ========================================================

function getAllCategories() {
    return fetchWrapper.get(`${baseUrl}`);
}


function getAllUser(){
    return fetchWrapper.get(`${baseUrl}/getAllUser`);
}

function getAllOnbox(){
    return fetchWrapper.get(`${baseUrl}/getAllOnbox`);
}

function getBaseSummary(){
    return fetchWrapper.get(`${baseUrl}/base-summary`);
}

function getTutorialMaterials(){
    return fetchWrapper.get(`${baseUrl}/tutorial-materials`);
}
// #endregion


//#region delets

function deleteCategory(id){
    return fetchWrapper.delete(`${baseUrl}/${id}`)
}

//#region puts

function updateCategoryDetails(params, id){
    return fetchWrapper.put(`${baseUrl}/update-category-details/${id}`, params)
}

//#endregion

//#endregion

/**
 * Creates a new category
 */
function createCategory(params){
    return fetchWrapper.post(`${baseUrl}/create`, params); 
}

function updateDatabase(params){
    return fetchWrapper.post(`${baseUrl}/update`, {
        database:params
    }); 
}

function toggleSharingCategory(id){
    return fetchWrapper.put(`${baseUrl}/toggle-sharing/${id}`)
}