import {
  CHANGE_MODAL_VISIBILITY,
  TOGGLE_DEMO
  } from '../actions/demoActions';
  
  const initialState = {
    modal:{show: false},
    demo:false
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case CHANGE_MODAL_VISIBILITY:
        return { ...state, modal:{...state.modal, show: !state.show} };
      case TOGGLE_DEMO:
        return { ...state, demo:action.value };
      default:
        return state;
    }
  }

  