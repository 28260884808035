import React, { PureComponent } from 'react';
import logo from "~img/logo.png";
import logotype from "~img/logotype.png";

class Topbar extends PureComponent {
  render() {
    return (
      <div className="topbar__login">
        <img src={logo} alt="onbox logo" className="logo"/>
        <img src={logotype} alt="onbox logotype" className="logotype"/>
      </div>
    );
  }
}

export default Topbar;
