import React from 'react'
import logo from "~img/logo.png";
import logotype from "~img/logotype.png";

export default function LogoContainer() {
    return (
        <div className="d-flex flex-column align-items-center">
        <img src={logo} alt="onbox logo" className="logo"/>
        <img src={logotype} alt="onbox logotype" className="logotype"/>
        <span className="logo-subtitle"></span>
      </div>
    )
}
