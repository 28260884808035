import { fetchWrapper } from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/editorservice";

export const editorService = {
    uploadImage,
    uploadTaskFiles
};



async function uploadImage(file) {
    return await fetchWrapper.postFile(`${baseUrl}/uploadImage`,file,false).then((res) => {return `${baseUrl}/${res.data}`});
}



async function uploadTaskFiles(files) {
    return await fetchWrapper.postFiles(`${baseUrl}/uploadTaskFiles`,files,false).then(res => res.data);
}


