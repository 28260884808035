import React from 'react';
import Scrollbar from 'smooth-scrollbar-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '~root/shared/prop-types/ReducerProps';
import SidebarLogo from './SidebarLogo'
import Mentor from '../mentor/Mentor'
import Footer from '../footer/Footer'
import {accountService} from '~root/_services'
import {Role} from '~root/_helpers'

const Sidebar = ({
  changeToDark, changeToLight,  sidebar,goToMain,showSidebar,hideSidebar, changeModalVisibility
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
  });

  return (
    <div className={sidebarClass}>
      <SidebarLogo goToMain={goToMain}/>

      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent
            goToMain={goToMain}
            onClick={hideSidebar}
            showSidebar = {showSidebar}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
            collapsed={sidebar.collapse}
            changeModalVisibility={changeModalVisibility}
          />
          
         
          
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            goToMain={goToMain}
            onClick={hideSidebar}
            showSidebar = {showSidebar}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
            collapsed={sidebar.collapse}
            changeModalVisibility={changeModalVisibility}
          />
        </div>
        {accountService.userValue.role===Role.Onboarder && <Mentor/>}
        
        <Footer/>
      </Scrollbar>
     
    </div>
  );
};

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default React.memo(Sidebar);
